<template>
  <div>
    <b-card>
      <div>
        <h3>
          Cadastrar fornecedor
        </h3>
      </div>
    </b-card>
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          <h4 class="mb-25">
            Informações básicas
          </h4>
          <p class="small mb-0 mt-25">
            <i>
              Os códigos de registro interno do sistema e registro interno Tiny são preenchidos automaticamente.
            </i>
          </p>
        </div>
        <div class="mr-2">
          <b-button
            :variant="form.situation === 'A' ? 'outline-success' : 'outline-danger'"
            class="btn-icon rounded-circle"
            @click="form.situation = form.situation === 'A' ? 'I' : 'A'"
          >
            <feather-icon icon="PowerIcon" />
          </b-button>
        </div>
      </div>
      <hr class="mt-0">
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Registro interno do sistema"
            label-for="id"
          >
            <b-form-input
              id="id"
              v-model="form.id"
              placeholder="Código de registro interno tiny"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Código interno Romildo*"
            label-for="internalId"
          >
            <b-form-input
              id="internalId"
              v-model="form.internalId"
              placeholder="Código interno da loja"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Registro interno Tiny"
            label-for="tinyId"
          >
            <b-form-input
              id="tinyId"
              v-model="form.tinyId"
              placeholder="Código de registro interno tiny"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Código"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="form.code"
              placeholder="Código do fornecedor"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Razão Social*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="Razão Social"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Nome*"
            label-for="fantasyName"
          >
            <b-form-input
              id="fantasyName"
              v-model="form.fantasyName"
              placeholder="Nome do Fornecedor"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Documento*"
            label-for="document"
          >
            <b-form-input
              id="document"
              v-model="form.document"
              type="number"
              placeholder="Documento"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Tipo"
          >
            <div class="d-flex">
              <b-form-checkbox
                v-model="form.type"
                value="J"
                class="custom-control-warning"
              >
                PJ
              </b-form-checkbox>
              <b-form-checkbox
                v-model="form.type"
                value="F"
                class="custom-control-info ml-2"
              >
                PF
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="mb-25">
        Registros fiscais
      </h4>
      <p class="small mb-0 mt-25">
        <i>
          Os registros fiscais não são obrigatórios para registro, mas indicamos preencher.
        </i>
      </p>
      <hr class="mt-0">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Tipo de inscrição"
            label-for="typeIe"
          >
            <v-select
              id="typeIe"
              v-model="form.ie"
              label="text"
              :options="typesIe"
              placeholder="Selecione o tipo de inscrição"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Inscrição Estadual"
            label-for="rg"
          >
            <b-form-input
              id="rg"
              v-model="form.rg"
              placeholder="Inscrição Estadual"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Inscrição municipal"
            label-for="im"
          >
            <b-form-input
              id="im"
              v-model="form.im"
              placeholder="Inscrição municipal"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="mb-25">
        Contato
      </h4>
      <hr>
      <b-row>
        <b-col md="3">
          <b-form-group
            label="Telefone*"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="form.phone"
              placeholder="Telefone"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Celular*"
            label-for="cell"
          >
            <b-form-input
              id="cell"
              v-model="form.cell"
              placeholder="Celular"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Fax"
            label-for="fax"
          >
            <b-form-input
              id="fax"
              v-model="form.fax"
              placeholder="Fax"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group
            label="E-Mail*"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              placeholder="E-Mail"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="E-Mail para envio da nfe"
            label-for="emailNfe"
          >
            <b-form-input
              id="emailNfe"
              v-model="form.emailNfe"
              placeholder="E-Mail para envio da nfe"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        Endereço
      </h4>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="CEP*"
            label-for="zipCode"
          >
            <b-form-input
              id="zipCode"
              v-model="form.zipCode"
              placeholder="CEP"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Pais"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="form.country"
              placeholder="Pais"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="UF*"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="form.state"
              placeholder="UF"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cidade*"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="form.city"
              placeholder="Cidade"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bairro*"
            label-for="district"
          >
            <b-form-input
              id="district"
              v-model="form.district"
              placeholder="Bairro"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Rua*"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="form.street"
              placeholder="Rua"
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="N°*"
            label-for="number"
          >
            <b-form-input
              id="number"
              v-model="form.number"
              placeholder="N°"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Endereço de cobrança é o mesmo ?"
          >
            <div class="d-flex">
              <b-form-checkbox
                v-model="sameAdress"
                value="1"
                class="custom-control-success"
                @change="defineSameAdress(1)"
              >
                Sim
              </b-form-checkbox>
              <b-form-checkbox
                v-model="sameAdress"
                value="0"
                class="custom-control-danger ml-2"
                @change="defineSameAdress(0)"
              >
                Não
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Complemento"
            label-for="complement"
          >
            <b-form-input
              id="complement"
              v-model="form.complement"
              placeholder="Complemento"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4>
        Endereço de cobrança
      </h4>
      <b-row>
        <b-col md="2">
          <b-form-group
            label="CEP*"
            label-for="billingZipCode"
          >
            <b-form-input
              id="billingZipCode"
              v-model="form.billingZipCode"
              placeholder="CEP"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="Pais"
            label-for="billingCountry"
          >
            <b-form-input
              id="billingCountry"
              v-model="form.billingCountry"
              placeholder="Pais"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="1">
          <b-form-group
            label="UF*"
            label-for="billingState"
          >
            <b-form-input
              id="billingState"
              v-model="form.billingState"
              placeholder="UF"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Cidade*"
            label-for="billingCity"
          >
            <b-form-input
              id="billingCity"
              v-model="form.billingCity"
              placeholder="Cidade"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Bairro*"
            label-for="billingDistrict"
          >
            <b-form-input
              id="billingDistrict"
              v-model="form.billingDistrict"
              placeholder="Bairro"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="10">
          <b-form-group
            label="Rua*"
            label-for="billingStreet"
          >
            <b-form-input
              id="billingStreet"
              v-model="form.billingStreet"
              placeholder="Rua"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group
            label="N°*"
            label-for="billingNumber"
          >
            <b-form-input
              id="billingNumber"
              v-model="form.billingNumber"
              placeholder="N°"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Complemento"
            label-for="billingComplement"
          >
            <b-form-input
              id="billingComplement"
              v-model="form.billingComplement"
              placeholder="Complemento"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-button
            block
            variant="danger"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col md="6">
          <b-button
            block
            variant="success"
            @click="createSuppplier()"
          >
            Enviar
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'CreateSupplier',
  data() {
    return {
      form: {
        internalId: '',
        tinyId: '',
        code: '',
        name: '',
        fantasyName: '',
        type: 'J',
        document: '',
        ie: '',
        rg: '',
        im: '',
        zipCode: '',
        country: 'Brasil',
        state: '',
        city: '',
        district: '',
        street: '',
        number: '',
        complement: '',
        billingZipCode: '',
        billingCountry: 'Brasil',
        billingState: '',
        billingCity: '',
        billingDistrict: '',
        billingStreet: '',
        billingNumber: '',
        billingComplement: '',
        phone: '',
        fax: '',
        cell: '',
        email: '',
        emailNfe: '',
        site: '',
        situation: 'A',
        obs: '',
        status: '',
        sent: '',
      },
      typesIe: [
        { text: 'Contribuinte', value: 'contribuinte' },
        { text: 'Isento', value: 'isento' },
        { text: 'Não Contribuinte', value: 'naoContribuinte' },
      ],
      sameAdress: 0,
    }
  },
  methods: {
    defineSameAdress(value) {
      if (value === 1) {
        this.form.billingZipCode = this.form.zipCode
        this.form.billingCountry = this.form.country
        this.form.billingState = this.form.state
        this.form.billingCity = this.form.city
        this.form.billingDistrict = this.form.district
        this.form.billingStreet = this.form.street
        this.form.billingNumber = this.form.number
        this.form.billingComplement = this.form.complement
      } else {
        this.form.billingZipCode = ''
        this.form.billingCountry = 'Brasil'
        this.form.billingState = ''
        this.form.billingCity = ''
        this.form.billingDistrict = ''
        this.form.billingStreet = ''
        this.form.billingNumber = ''
        this.form.billingComplement = ''
      }
    },
    async createSuppplier() {
      if (this.form.name.length < 3
        || this.form.fantasyName.length < 3
        || this.form.document.length < 3
        || this.form.internalId.length < 3
        || this.form.phone.length < 8
        || this.form.cell.length < 8
        || this.form.email.length < 6
        || this.form.zipCode.length < 8
        || this.form.state.lenght < 2
        || this.form.city.length < 2
        || this.form.district.length < 2
        || this.form.street.length < 2
        || this.form.number.length < 1
        || this.form.billingZipCode.length < 8
        || this.form.billingState.length < 2
        || this.form.billingCity.length < 2
        || this.form.billingDistrict.length < 2
        || this.form.billingStreet.length < 2
        || this.form.billingNumber.length < 1) {
        this.$toast.error('Preencha os dados !')
      } else {
        const { data } = await this.$http.post('/suppliers/create', this.form)
        if (data.success) {
          this.$toast.success('Fornecedor criado com sucesso!')
          this.$router.push('/fornecedor')
        } else {
          this.$toast.error('Erro ao cadastrar o fornecedor')
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
